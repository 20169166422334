.block {
  padding: 20px;
  border-bottom: solid 1px #f5f5f5;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  border-radius: 3px;
  width: 100%;
  background-color: white;
  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.payment-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
}

.RenderWeek {
  width: 1500px;
    height: 400px;
    overflow: scroll;
    border: 1px solid #777777;
    
    table {
      border-spacing: 0
    }

    th {
      border-left: none;
      border-right: 1px solid #bbbbbb;
      padding: 5px;
      width: 80px;
      min-width: 80px;
      position: sticky;
      top: 0;
      color: black;
      font-weight: normal;
    }

    td {
      border-left: none;
      border-right: 1px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb;
      padding: 5px;
      width: 80px;
      min-width: 80px;
    }
}
.RenderWeek th:nth-child(1),
.RenderWeek td:nth-child(1) {
    position: sticky;
    left: 0;
    width: 150px;
    min-width: 150px;
}

.RenderWeek th:nth-child(2),
.RenderWeek td:nth-child(2) {
    position: sticky;
    /* 1st cell left/right padding + 1st cell width + 1st cell left/right border width */
    /* 0 + 5 + 150 + 5 + 1 */
    left: 150px;
    width: 100px;
    min-width: 100px;
}
.RenderWeek th:nth-child(3),
.RenderWeek td:nth-child(3) {
    position: sticky;
    /* 1st cell left/right padding + 1st cell width + 1st cell left/right border width */
    /* 0 + 5 + 150 + 5 + 1 */
    left: 250px;
    width: 100px;
    min-width: 100px;
}

.RenderWeek td:nth-child(1),
.RenderWeek td:nth-child(2),
  .RenderWeek td:nth-child(3){
    background: #b6b5ff;
}

.RenderWeek th:nth-child(1),
.RenderWeek th:nth-child(2),
  .RenderWeek th:nth-child(3) {
    z-index: 2;
}
