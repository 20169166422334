.block {
  padding: 20px;
  border-bottom: solid 1px #f5f5f5;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  border-radius: 3px;
  background-color: white;
  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.payment-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
}

.group-button-action-form {
  justify-content: 'space-around';
  display: 'flex';
  flex-direction: 'row';
  height: 50px;
}
.button-action {
  margin-left: 3px;
  margin-right: 3px;
}
