.container {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  border-radius: 14px;
}

.button {
  justify-content: center;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 70px;
}
.text-button {
  width: 111px !important;
  height: 36px !important;
  font-weight: 700 !important;
  font-size: 13.7952px !important;
  line-height: 18px !important;
  color: #1e4db7 !important;
  border: 0.7664px solid #1e4db7 !important ;
  border-radius: 7px !important;
}
.container-item {
  padding: 34px;

  // border-radius: 15px;
}
.image {
  // border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  width: 100%;
  height: 605.23px;
  padding: 0 !important;
}
.container-content {
  // padding: 22px;
  display: flex;
  justify-content: space-between;
  // margin-top: 12px;
}
.container-icon {
  font-size: 32px;
  color: #6c757d;
  // background: #6C757D;
}
.icon {
  padding: 10px;
  cursor: pointer;
}
.rate {
  font-size: 18px !important;
  margin-right: 0 !important;
  color: #fcac12 !important;
  border-color: #fcac12 !important;
}
.rate > li {
  font-size: 18px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.rate > p {
  font-weight: 400 !important;
  font-size: 14.9205px !important;
  line-height: 19px !important;
  color: #6c757d !important;
}

.container-button-sell {
  // padding: 10px;
  margin-top: 2px;
  margin-bottom: 9px;
}
.button-sell {
  width: 150px !important;
  height: 36px !important;
  background: #1e4db7 !important;
  border-radius: 7px !important;
  color: #ffff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px !important;

  /* Base/Light/Light 100 */

  color: #ffffff;
}
.container-button-add {
  // padding: 10px;
}
.button-add {
  width: 150px !important;
  height: 36px !important;
  background: #edfaff !important;
  border-radius: 7px !important ;
  // color: #ffff ;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  color: #0bb2fb !important;
  border: none !important;
}
.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(197, 184, 184);
}
.container-name-drep {
  width: 422px;
}
.container-name-drep > p {
  font-weight: 500;
  font-size: 18.7223px;
  line-height: 23px;
  letter-spacing: -0.585071px;
  color: #292b2d;
}
.text-price {
  font-weight: 600;
  font-size: 18.7223px;
  line-height: 23px;
  letter-spacing: 0.585071px;
  color: #3b6cdc;
  margin-bottom: 0 !important;
}
.text-like {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #6c757d;
}
.text-like > a {
  color: #0bb2fb;
}
.text-drep {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #6c757d;
}
.icon-btn {
  font-size: 16px;
}
