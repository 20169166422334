.product {
  height: 351px;

  &__img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 8px 8px 0 0 !important;
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    text-overflow: ellipsis !important;
    overflow: hidden;
    margin: 0 !important;
  }

  &__description {
    font-size: 10px;
  }

  &__block {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  &__block-price {
    margin: 5px 0;
  }
  &__discount {
    background-color: #fcac12;
    padding: 0 8px;
    color: #fff;
  }

  &__deal {
    border: 1px solid #fd3c4a;
    padding: 0 5px;
    color: #fd3c4a;
  }

  &__price,
  &__priceOld {
    font-size: 14px;
  }
  &__price {
    color: #1e4db7;
    font-weight: 600;
  }

  &__priceOld {
    color: #91919f;
    text-decoration: line-through;
  }

  &__rate {
    color: #fcac12 !important;
    font-size: 14px !important;
  }

  &__rate > li:not(:last-child) {
    margin-right: 2px !important;
  }

  &__amount {
    color: #6c757d;
    font-size: 12px;
  }
}
