.form {
  &__item {
    align-items: center;
  }

  &__input::placeholder {
    font-weight: 400 !important;
    color: #7a869a !important;
  }

  &__input {
    font-size: 14px !important;
    height: 40px !important;
    border: 1px solid #e3e5e5 !important;
    border-radius: 3px !important;
  }

  &__select {
    font-size: 14px !important;
    border-radius: 3px !important;
    height: 40px !important;
  }
}
