.card {
  border: 1px solid;
  margin: 10px 100px 0;
  padding: 10px 83px;
  border-radius: 16px;

  &__detail {
    background: #f5f5f5;
    padding: 0 23px 35px 23px;
  }
}

.btnShift {
  border: 1px solid #d5d5d5;
  border-radius: 9px;
  padding: 20px;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  &__time {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }

  &__info {
    list-style-type: none;
    font-weight: 400;
    font-size: 14px;
  }

  &__items > li:not(:first-child) {
    margin-top: 10px;
  }

  &__control {
    display: flex;
    align-items: center;
    column-gap: 5px;

    & > div {
      width: calc(100% - 110px - 5px);
    }
  }
}

.btnShift :hover {
  cursor: pointer;
}

.btnShiftDay {
  border: 1px solid #d5d5d5;
  border-radius: 9px;
  padding: 10px 20px;
  margin: 0 0 5px 30px;
  justify-content: space-around;
  align-items: center;
}

.btnShiftName {
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
}

.itemCard {
  background-color: red;
  float: left;
}

.RenderWeek {
  width: 100%;
  height: auto;

  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    table-layout: fixed;
    margin-bottom: 3em;
  }

  th {
    border: 1px solid black;
    padding: 5px;
    min-width: 60px;
    position: sticky;
    top: 0;
    color: rgb(255, 255, 255);
    font-weight: bold;
    border: 1px solid black;
  }

  td {
    border-left: none;
    padding: 5px;
    text-align: center;
    border: 1px solid black;
  }
}
.card-register :hover {
  cursor: pointer;
}

.tableIndex {
  width: 90%;
  // border-collapse:collapse ;
  // border: 1px solid;
  table-layout: fixed;
  margin-left: 68px;
  margin-bottom: 5em;
}

.tableIndex th {
  // border-collapse:collapse ;
  // border: 1px solid;
  color: #0089df;
  font-size: 20px;
}
.tableIndex th :hover {
  cursor: pointer;
}
.tableIndex th :hover {
  cursor: pointer;
}

.tableIndex td {
  // border-collapse:collapse ;
  border-right: 1px solid rgb(199, 199, 199);
}

.celltableIndex {
  font-size: 15px;
  width: 100%;
  color: #0089df;
  margin-bottom: 60px;
  padding: 5px;
  background-color: #f0f5ff;
  height: 4em;
}

.missing {
  background-color: red;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: white;
  font-weight: bold;
  text-align: center;
  transform: translateY(12px) translateX(45px);
}

.nameStaff {
  // border-right: 1px solid;
  float: left;
  width: 45%;
  margin: 5px;
  // font-size: 13px;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: black;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid;
  margin-bottom: 3em;
}

.card {
  background: #f5f5f5;
}

.table {
  &__header {
    text-align: center;
    background: #fff;
    border-right: 1px solid #dfdfdf;
  }

  &__day {
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }

  &__date {
    font-size: 14px;
    margin: 0;
  }

  &__cell {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    min-height: 105px;
    vertical-align: top;
  }

  &__info {
    height: 100%;
    max-width: 100%;
  }

  &__box {
    background: #fff;
    padding: 65px 37px;
  }

  &__time {
    display: block;
    font-weight: 700;
    font-size: 14px;
    color: #1e4db7;
  }

  &__staffs {
    // display: flex;
    // justify-content: space-between;
    margin: 0;
    width: 100%;
  }

  &__staff {
    list-style-type: none;
    display: inline-block;
    color: #1e4db7;
    font-weight: 500;
    font-size: 11px;
    margin-right: 10px
  }

  &__staff-empty {
    @extend .table__staff;
    color: #91919f;
  }

  &__staff-box-deco {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__staff-box {
    padding: 5px 17px !important;
    height: 43.5%;
  }

  &__staff-box-empty {
    @extend .table__staff-box;
    @extend .table__staff-box-deco;
    color: #91919f;
  }

  &__slot {
    background: #F0F0F0;
    padding: 8px 17px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 700;
    color: #0bb2fb;
    text-align: center;
    padding: 2px 9px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
  }

  &__missing {
    background: rgb(255, 42, 42);
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 13px;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.input {
  &__ca {
    max-width: 208px !important;
  }

  &__time {
    padding: 10px auto !important;
    max-width: 310px !important;
  }

  &__select {
    border: none !important;
    font-size: 14px !important;
    border-radius: 3px !important;
    height: 40px !important;
    width: 90%!important;
  }

  &__total {
    min-width: 132px !important;
    width: 100%;
    display: flex !important;
    align-items: center !important;
    
  }
}

.row {
  justify-content: space-between;
  flex-wrap: wrap;
}

.column {
  &__left {
    width: 38%;
  }

  &__right {
    width: 60%;
  }
}

.form-item {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.schedule {
  &__layout {
    padding: 20px 20px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
  }

  &__layout-header {
    padding: 35px 60px;
    width: 100%;
  }
}

.tbShop {
  &__cell {
    &-name,
    &-register,
    &-choose {
      font-weight: 700;
      font-size: 16px;
      color: #1e4db7;
      border: none !important;
    }

    &-name {
      width: 5%;
      background: #eaf0ff;
      font-weight: 500;
    }
    &-register {
      width: 6.5%;
      background: #f0f5ff;
    }
    &-choose {
      width: 6%;
      background: #eaf0ff;
    }

    &-slot {
      width: 10.5%;
      border-bottom: 1px solid #dfdfdf !important;
      border-right: 1px solid #dfdfdf;
      padding: 16px 16px 35px 16px !important;
    }
  }

  &__day {
    @extend .table__day;
  }

  &__date {
    @extend .table__date;
    color: #91919f;
    font-weight: 400;
  }

  &__slots {
    margin: 0;
  }

  &__slots > * {
    margin-top: 7px;

    &:first-child {
      margin: 0;
    }
  }

  &__slot {
    list-style-type: none;
    background: #cdcdcd;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }
}
