



/* layout-table */
.product-smart-columns {
    tr:hover > td {
      background: #f0f5ff !important;
    }
    & thead > tr > th {
      }
  }
  