.container-top {
    width: 100% !important;
    height: 200px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
    margin-top: 8px;
  }
  .container-bot {
    width: 100% !important;
    height: 100% !important;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .title-container {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .dashboard_manager_bottom_row_col_parent_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    border-bottom: 1px solid rgb(197, 184, 184);
  }
  .container-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
  .text-height {
    -webkit-line-clamp: 2 !important; /* số dòng hiển thị */
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: nowrap;
  }
  .button-edit {
    background-color: blueviolet !important;
    color: #fff !important;
    height: 40px !important;
  }
  .title-info {
    font-size: 16px;
    font-weight: 400;
  }
  
  .display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
  }
  .tabpane {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 34px;
  }
  