.font-bold {
    margin-bottom: 43px;
    margin-top: 62px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}


.point-bottom-right-radio {
    display: flex;
    flex-direction: column;
}

.font-span {
    color: #1e4db7;
    font-family: Arial, Helvetica, sans-serif;
}

.label-check {
    width: 150%;
    display: flex;
}