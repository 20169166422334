.pw-new-container {
  position: relative;
  color: #fff;

  .pw-new-content {
    position: absolute;
    width: 50%;

    .pw-new-button {
      margin-top: 15px;
      background: #000 !important;
      border-color: #000 !important;
      font-size: 16px;
      color: #fff !important;
    }
  }
}
