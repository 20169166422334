.body_channel {
  background-color: white;
  padding: 16px;
  margin: 15px 15px;
  border-radius: 5px;
}
.body_channel_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
.body_channel_header_title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.body_channel_header_list_text {
  font-size: 1.2rem;
  font-weight: 600;
  padding-right: 10px;
}
.button_create_channel {
  background-color: #007bff;
  color: white;
}
.body_channel_filter {
  padding: 15px 0;
}
.body_channel_delete_filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.body_channel_table_action {
  display: flex;
  flex-direction: column;
  align-items: center;
}
