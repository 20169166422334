.form-vnpay {
    display: flex;
    flex-direction: row;
    .form-title {
        display: flex;
        flex-direction: column;
        width: 370px;
        > span {
            font-size: 15px;
            font-weight: 500;
        }
    }
    .form-input {
    }
}