.block {
  padding: 20px;
  border-bottom: solid 1px #f5f5f5;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  border-radius: 3px;
  background-color:'white';
  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}
.payment-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
}
.imgAvatar{
  width: 50px; 
  height: 50px;
   line-height: 50px; 
   font-size: 18px
}
.sell-product__item-note {
  margin-bottom: 0px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 90px;
}