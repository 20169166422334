.content-modal{
    max-height: 450px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 4px !important;
      }
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.5) !important;
        display: none !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #babac0 !important;
        border-radius: 10px !important;
        // display: none !important;
      }
}