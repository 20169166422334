
.promotion-add__box{
    border: solid 1px rgba(184, 184, 184, 0.486);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: white;
    .promotion-add__title{
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
    }

}

.promotion_add_button{
    display: flex;
    justify-content: end;
    margin-top: 20px;
}