.view_product {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 1.25rem;
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}

.variant-image {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px dashed #bcc6ff;
  transition: all 0.3s;
  &:hover {
    border-color: #5f73e2;
  }
}

.variant-columns {
  margin-top: 10px;
  margin-left: 50px;
  width:80%;
  tr:hover > td {
    background: #E6ECFF !important;
  }
}

.product-upload-img div[class='ant-upload ant-upload-select ant-upload-select-picture-card'] {
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.5) !important;
  background-color: #fff !important;
  height: 170px !important;
  width: 170px !important;
}

.product-upload-image div[class='ant-upload ant-upload-select ant-upload-select-picture-card'] {
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.5) !important;
  background-color: #fff !important;
  height: 130px !important;
  width: 130px !important;
}
