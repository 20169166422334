.displaygrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
}

.container{
    width: 460px;
    height: 180px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    display: flex;
}
.img{
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    // padding: 8px;
}
.content{
    width: 100%;
    margin-left: 8px;
    margin-top: 12px;
}
.title{
    font-weight: 700;
    margin-bottom: 4px !important;
}
.note{
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0 !important;
}
.icon{
    font-size: 28px;
    color: #de3434;
    // width: 32px;
    // height: 32px;
}
.styleItem {
    margin-bottom: 8px;
    width: 240px;
    height: 160px;
    border: 1px solid rgb(197, 184, 184);
    border-radius: 4px;
  }
.styleDiv  {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  }
.imei-item {
  cursor: pointer;
  .imei-item-span{
    margin-left: 5px;
  }
}

.imei-item:hover {
  background: silver !important;
}
.imei-item-select{
  border-radius: 10px;
}
.sell-product__item-note {
    margin-bottom: 0px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 90px;
  }