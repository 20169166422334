.btn {
  width: 100%;
  padding: 8px 25px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &__yellow {
    font-size: 13px !important;
    font-weight: 600 !important;
    background: #fceed4 !important;
    color: #d58c00 !important;
    border-radius: 7px !important;
  }

  &__blue {
    font-weight: 700;
    font-size: 13.7952px !important;
    line-height: 18px !important;
    color: #1e4db7 !important;
    border: 0.7664px solid #1e4db7 !important ;
    border-radius: 7px !important;
  }

  &__blue-fill {
    @extend .btn__blue;
    color: #fff !important;
    background-color: #1e4db7 !important;
    font-weight: 600;
    font-size: 13px !important;
  }

  &__blue-sky {
    color: #0bb2fb !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 7px !important;
  }
  &__blue-sky-fill {
    @extend .btn__blue-sky;
    padding: 8px 0 !important;
    background-color: #edfaff !important;
  }

  &-primary {
    background: #0bb2fb !important;
    border-radius: 7px !important;
    padding: 8.4304px 19.16px !important;
    color: #fff !important;
    font-weight: 700;
    font-size: 14px !important;
  }
}
