.sell-container {

  .sell-header {
    width: 100%;
    height: 48px;
    max-height: 48px;
    background-color: #5f73e2;
    padding: 8px 5px;
    display: flex;
    align-items: center;
    z-index: 10;
    .search-product {
      max-width: 500px;
      border-radius: 8px;
    }

    .name-branch,
    .name-user {
      margin-bottom: 0;
      font-weight: 500;
      color: white;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  .sell-content {
    display: flex;
    background-color: #eff1f5;

    .sell-right {
      background-color: white;
      width: 31%;
      min-height: calc(100vh - 55px);
      display: flex;
      flex-direction: column;
      padding: 0px 5px;
      position: relative;
      padding-top: 8px;
      overflow-y: auto;
      max-height: 800px;
      margin-top: 10px;
      .sell-right__footer-btn {
        position: fixed;
        bottom: 10px;
        right: 0px;
        z-index: 10;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }

    .sell-left {
      width: 69%;
      margin-right: 10px;

      .sell-products-invoice {
        width: 100%;
        min-height: 400px;
        overflow-y: auto;
        margin-bottom: -7px;
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 10px;

        .sell-product-header {
          font-size: 15px;
          font-weight: 600;
          min-height: 40px;
          max-height: 40px;
          border-bottom: 0.5px solid #cac7c7;

          .header-stt {
            width: 17px;
            text-align: center;
          }

          .header-remove {
            width: 50px;
            text-align: center;

          }

          .header-sku {
            width: 100px;
            // margin-left: 30px;
            white-space: nowrap;
            text-align: center;

          }

          .header-name {
            // margin-left: 16px;
            // min-width: 175px;
            // max-width: 175px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

          }

          .header-unit {
            width: 100px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;

          }

          .header-quantity {
            width: 130px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }

          .header-price {
            width: 130px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }

          .header-sum-price {
            width: 130px;
            text-align: end;
          }
        }

        .sell-product__item {
          width: 100%;
          padding: 30px 9px;
          min-height: 50px;
          max-height: 60px;
          // border-bottom: 0.5px solid #cac7c7;
          transition: background-color 0.5s ease;

          .sell-product__item-sku {
            margin-bottom: 0px;
            // margin-left: 15px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            // width: 110px;
            cursor: pointer;
          }

          .sell-product__item-name {
            margin-bottom: 0px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            min-width: 180px;
            max-width: 180px;
            text-align: center;
          }

          .sell-product__item-unit {
            width: 100px;
            border-bottom: 0.5px solid #cac7c7;
          }

          .sell-product__item-quantity {
            width: 100px;
            border-bottom: 0.5px solid #cac7c7;
          }

          .sell-product__item-price {
            width: 130px;
            border-bottom: 0.5px solid #cac7c7;
          }

          .sell-product__item-note {
            margin-bottom: 0px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            max-width: 90px;
          }
        }
      }

      .sell-products-related {
        background-color: white;
        padding: 16px;
        margin-top: -7px;

        .list-product-related {
          width: 100%;
          padding-top: 20px;
          padding-bottom: 20px;
          overflow-y: auto;
          max-height: 400px;
          .product-item-wrap {
            width: 20%;
            padding: 3px;

            .product-item {
              max-width: 11rem;
              height: 10rem;
              cursor: pointer;
              border: 0.5px solid #cbcbcb;
              border-radius: 10px;
              margin-bottom: 3%;

              .product-item__name {
                margin-bottom: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                font-size: 0.8rem;
                color: white;
                font-weight: 600;
              }

              .product-item__price {
                font-size: 0.7rem;
                font-weight: 300;
                padding-left: 5px;
                padding-right: 5px;
                margin-bottom: 0px;
                color: white;
              }
            }
          }
        }
      }

      .keyboard-shorcuts {
        background-color: #b5cefe;
        border-radius: 5px;
        width: 220px;
        height: 55px;
        font-weight: 600;
        margin-bottom: 30;
        padding-left: 10;
        padding-right: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media screen and (max-width: 1920px) {
  .sell-container {
    background-color: white !important;
    .sell-content {
      .sell-left {
        .sell-products-related {
          .list-product-related {
            .product-item-wrap {
              .product-item {
                .product-item__price {
                  font-size: 10px;
                  font-weight: 600;
                  padding-left: 5px;
                  padding-right: 5px;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1848px) {
  .sell-container {
      max-width: 1848px;
      overflow-x: auto;
    }
}
.warning{
  font-size: 13px;
  width: 120px;
  color: #1890ff;
  margin-left: 4px;
  margin-bottom: 0 !important;
}
.row_bonus{
  width: 100%;
    border-bottom: 0.5px solid #cac7c7;
}