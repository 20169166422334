.price-cost {
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
}

.price-cost:hover{
    background-color: rgb(238, 236, 236) !important;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5%;
}