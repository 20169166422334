.container {
    margin: 20px 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.step-1, .step-2, .step-3 {
    margin-top: 10px;
}

.import-steps {
    width: 100%;
}
.step-2-header {
    margin-bottom: 20px;
}

.step-container {
    min-height: 20px;
    width: 100%;
    border-radius: 10px;
}

.group-button {
    margin-top: 5px;
}

.header-item {
    margin-bottom: 20px;
}

.result {
    width: 100%;
    height: 400px;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    padding-top: 81px
}
