.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.percent{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}