.dashboard_manager {
  display: flex;
  flex-direction: column;
  margin: 12px;
  max-height: 100%;
}
.dashboard_manager_balance {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding: 12px;
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
}

.dashboard_manager_balance_title {
  width: 100%;
  padding-left: 1rem;
  display: flex;
  border-bottom: 1px solid rgb(204, 189, 189);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: flex-start;
}
.dashboard_manager_balance_title > div {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.dashboard_manager_revenue_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(206, 192, 192);
}
.dashboard_manager_revenue_title > div {
  color: black;
  font-size: 1rem;
  font-weight: 600;
}
.dashboard_manager_bottom {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.dashboard_manager_bottom_row_col_parent_top > div {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}
.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(197, 184, 184);
}

.card-overview {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
  background-color: white;
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
.header_product_out_stock{
  width: 80%;
  display: flex;
}