.form_modal{
    position: fixed;
    top:20;
    left: 0;
    background: #0008;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    overflow: auto;
    form{
        max-width: 600px;
        width: 100%;
        background: white;
        margin: 30px auto;
        padding: 20px;
        border-radius: 5px;
    }
}
.form_modal 

.form_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    transform: translateY(-13px);
} 
.form_header span{
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
    transform: translateY(-5px);
}

.form_body textarea{
    width: 100%;
    min-height: 100px;
    border: none;
    outline: none;
    resize: none;
}
.form_body .input_images{
    display:flex;
    justify-content: center;
    margin: 10px 0;
}
.form_body .input_images box-icon{
    font-size: 2rem;
    cursor: pointer;
}
.form_body .input_images .file_upload{
    overflow: hidden;
    margin: 0 10px;
    position: relative;
}
.form_body .input_images .file_upload #file{
    position: absolute;
    top:0;
    left: 0;
    opacity: 0;
}
.form_body .show_images{
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px ;
    grid-gap: 10px;
    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display:grid;
}
.form_body .show_images #file_img{
    position:relative;
    width: 100%;
    height: 100%;
}
.form_body .show_images #file_img img,
.form_body .show_images #file_img video
{
    width: 100%;
    height: 100%;
    max-height: 100px;
    display: block;
    object-fit: contain;
    
}

.status_body .show_images #file_img span{
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: crimson;
    padding: 3px 7px;
    border: 1px solid crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;
}
.form_footer button{
    width: 100%;
    transition-duration: 0.4s;
}
.form_footer button:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    background-color:white;
}