.card {
  align-items: center;
  flex-wrap: wrap;
  column-gap: 35.5px !important;

  &__time {
    font-weight: 700;
    font-size: 12.5px;
    margin: 0;
  }

  &__time-box {
  }

  &__btn {
    background: #0bb2fb;
    border-radius: 7px;
    width: 197px;
    height: 35.86px;
    padding: 8.4304px 19.16px;
  }
}

.time {
  &__text {
    color: #0d0e0f;
  }

  &__number {
    color: #1e4db7;
    font-size: 12.2553px;
    padding-left: 7px;
  }
}
.btn_delete{
  background: #ff2828;
  border-radius: 7px;
  width: 197px;
  height: 38.86px;
  color: aliceblue;
  border: none;
  font-weight: bold;
  cursor: pointer;
}


.btn_add{
  background: #1ddd47;
  border-radius: 7px;
  height: 38.86px;
  color: aliceblue;
  border: none;
  font-weight: bold;
  width: 19em;
  cursor: pointer;
}