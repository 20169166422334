.promotion_manager {
  display: flex;
  background-color: white;
  margin: 1rem;
  padding: 1rem;
  flex-direction: column;
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}
