
.dropdown-menu{
    display: flex;
    background-color: #fff;
    justify-content: flex-start;
    border: 1px solid rgb(230, 218, 218);
    max-width: 20rem;
    border-radius: 5px;
    flex-direction: column;
    color: #565656;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-bottom: 10px;
    .dropdown-item {
        cursor: pointer;
        width: 100%;
        padding: 10px 10px 0px 10px 
    }
}
.title{
  margin-left: 60px;
  display: flex;
}
.layout-form {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      .name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: #101828;
        flex: none;
        margin-bottom: 12px;
      }
      .layout-form-footer{
        display: flex;
        width: 100%;
        margin-bottom: 10px;
      }
      .btn-shift {
        width: 100%;
        margin: 5px;
        background-color: #335BD3;
        border-color: #325de1;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
        height: 44px;
        font-family: 'Inter';
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        font-style: normal;
        cursor: pointer;
      }
      .btn-cancel {
        width: 100%;
        margin: 5px;
        background-color: #FFFFFF;
        border-color: #325de1;
        color: #325de1;
        font-size: 20px;
        font-weight: 500;
        height: 44px;
        font-family: 'Inter';
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        font-style: normal;
        cursor: pointer;
      }
  }
  .label-style {
    width: 89px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .list-shift{
    font-family: 'Inter';
    font-style: normal;
    position: relative;
    width: 95%;
    background-color: white;
    margin: 0 0 10px 20px ;
    &__name{
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: #101828;
        flex: none;
        margin-bottom: 12px;
    }
    &__name-role{
      font-weight: 700;
      font-size: 16px;
      color: #101828;
      flex: none;
    }

    &__out-shift{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #f9f0f0;
    }
    &__btn-out{
        width: 25%;
        background-color: #335BD3 !important;
        color: #FFFFFF !important;
        font-family: 'Inter';
    }
  }
  .title-staff {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #101828;
    flex: none;
    margin-bottom: 12px;
    margin-left: 20px !important;
  }