.displaygrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
}

.container{
    width: 400px;
    height: 150px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 8px;
    display: flex;
}
.img{
    width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    // padding: 8px;
}
.content{
    width: 100%;
    margin-left: 8px;
}
.title{
    font-weight: 700;
    margin-bottom: 4px !important;
}
.note{
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0 !important;
}
.icon{
    font-size: 28px;
    color: #de3434;
    // width: 32px;
    // height: 32px;
}