.styleItem {
  margin-bottom: 8px;
  width: 240px;
  height: 160px;
  border: 1px solid rgb(197, 184, 184);
  border-radius: 4px;
}

.styleDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
}

.imei-item {
  cursor: pointer;

  .imei-item-span {
    margin-left: 5px;
  }
}

.imei-item:hover {
  background: silver !important;
}

.imei-item-select {
  border-radius: 10px;
}

.disabled-row {
  background: #cccc !important;
  pointer-events: none;
}

.table-row-light {
  background-color: #cccc;
}

.table-row-dark {
  background-color: red;
}
  .transport-product-header {
    font-size: 15px;
    font-weight: 600;
    min-height: 40px;
    max-height: 40px;
    border-bottom: 0.5px solid #cac7c7;

    .header-stt {
      width: 17px;
      text-align: center;
    }

    .header-remove {
      width: 50px;
      text-align: center;

    }

    .header-sku {
      width: 100px;
      // margin-left: 30px;
      white-space: nowrap;
      text-align: center;

    }

    .header-name {
      // margin-left: 16px;
      // min-width: 175px;
      // max-width: 175px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

    }

    .header-unit {
      width: 100px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;

    }

    .header-quantity {
      width: 130px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .header-price {
      width: 130px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .header-sum-price {
      width: 130px;
      text-align: end;
    }
  }

  .transport-product__item {
    width: 100%;
    padding: 30px 9px;
    min-height: 50px;
    max-height: 60px;
    // border-bottom: 0.5px solid #cac7c7;
    transition: background-color 0.5s ease;

    .transport-product__item-sku {
      margin-bottom: 0px;
      // margin-left: 15px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      // width: 110px;
      cursor: pointer;
    }

    .transport-product__item-name {
      margin-bottom: 0px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      min-width: 180px;
      max-width: 180px;
      text-align: center;
    }
  }

  .row_bonus{
    width: 100%;
      border-bottom: 0.5px solid #cac7c7;
  }



.list-product {
  border-collapse: collapse;
  width: 100%;
}

.list-product td,
.list-product th {
  border: 1px solid rgb(15, 15, 15);
  padding: 5px;
  min-width: 5px;
  text-align: center;
}

.list-product th {
  padding-top: 15px;
  padding-bottom: 10px;
  width: 10em;
}

.list-product i {
  cursor: pointer;
}

.list-product .fa-trash-alt {
  background: rgb(121, 121, 121);
  padding: 7px;
  color: white;
  font-size: 15px;
}

.delivery {
  display: flex;
  border: 1px solid ;
  border-radius: 8px;
  background-color: #5f73e2;
  padding: 8px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-weight: 600;

  &__title{
    margin-right: 10px;
    font-size: 16px;
  }


  &__quantity {
    background: rgb(255, 42, 42);
    font-weight: 700;
    font-size: 13px;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
