.container{
    display: flex;
    margin-top: 10px;
}

.containerRight{
    width: 65%;
    border-radius: 8px;
    margin-bottom: 12px;
    margin-left: 10px;
}

.containerLeft{
    margin-bottom: 12px;
    width: 35%;
}
.containerCON{
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    width: 100%;
    background-color: white;
}
.row{
    justify-content: space-between;
}
.location {
    width: 20em;
    margin-top: 30px!important;
}
.luu {
    margin-left: 10px;
    width: 20%;
    align-items: flex-end;
}
.view_history {
    color: #1a75ff;
    text-decoration:underline;
    size: 14px;
    cursor: pointer;
}
.btnShift {
    border: 1px solid #d5d5d5;
    border-radius: 9px;
    padding: 20px;
    margin-bottom: 5px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  
    &__time {
      font-weight: 400;
      font-size: 14px;
      margin: 0;
    }
  
    &__title {
      font-weight: 700;
      font-size: 20px;
      margin: 0;
    }
  
    &__info {
      list-style-type: none;
      font-weight: 400;
      font-size: 14px;
    }
  
    &__items > li:not(:first-child) {
      margin-top: 10px;
    }
  
    &__control {
      display: flex;
      align-items: center;
      column-gap: 5px;
  
      & > div {
        width: calc(100% - 110px - 5px);
      }
    }
  }
  
  .btnShift :hover {
    cursor: pointer;
  }
  .list-vote {
    font-size: 15px;
    font-weight: 600;
    min-height: 40px;
    max-height: 40px;
    border-bottom: 0.5px solid #cac7c7;
    .header-name {
      text-align: center;
      margin-left: auto;
      margin-right: auto;

    }
  }
  .vote__item {
    width: 100%;
    min-height: 50px;

    .vote__item-name {
      margin-bottom: 0px;
      font-weight: 500;
      overflow: hidden;
      text-align: center;
    }
  }
