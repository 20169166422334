.item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: whitesmoke;
    border: 1px solid silver;
    padding: 14px;
    cursor: pointer;
    .item-header-1 {
        display: flex;
        flex-direction: row;

    }
    .item-header-2 {
        display: flex;
        flex-direction: row;
    }
}
.item-tree {
    background-color: white;
    border: 1px solid silver;
    padding: 14px;
}