.container {
    margin: auto;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.step-1, .step-2, .step-3 {
    margin-top: 20px;
}

.import-steps {
    width: 100%;
}
.step-2-header {
    margin-bottom: 30px;
}

.step-container {
    min-height: 130px;
    width: 100%;
    border: 1px solid #dbdcdd;
    border-radius: 10px;
    padding: 20px;
}

.group-button {
    margin-top: 50px;
}

.header-item {
    margin-bottom: 20px;
}

.result {
    width: 100%;
    height: 600px;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: center;
    padding-top: 81px
}
