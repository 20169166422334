// form create shipping controll
.form-input {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .form-left {
        width: 45%;
        .item-left-1{
            display: flex;
            flex-direction: row;
            margin-top: 2.5%;
            .span {
                font-weight: 500;
                width: 200px;
                font-size: 15px;
            }
        }
        .item-left-2{
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            .span {
                font-weight: 500;
                width: 200px;
                font-size: 15px;
            }
        }
    }
    .form-right{
        width: 45%;
        .item-right-1{
            display: flex;
            flex-direction: row;
            margin-top: 2.5%;
            width: 100%;
            .span {
                font-weight: 500;
                width: 200px;
                font-size: 15px;
            }
        }
        .item-right-2{
            display: flex;
            flex-direction: row;
            margin-top: 8px;
            justify-content: space-between;
            .item-right-child-1 {
                width: 40%;
                margin-right: 1%;
                .span {
                    font-weight: 500;
                    width: 200px;
                    font-size: 15px;
                }
            }
            .item-right-child-2 {
                width: 40%;
                .span {
                    font-weight: 500;
                    width: 200px;
                    font-size: 15px;
                }
            }
            .item-right-child-3 {
                width: 18%;
                margin-left: 2%;
                display: flex;
                align-items: center;

            }
        }
        .item-right-3{
            display: flex;
            flex-direction: column;
            margin-top: 1.5%;
            width: 100%;
            .span {
                font-weight: 500;
                width: 100px;
                font-size: 15px;
            }
        }
    }
}
.footer {
    display: flex;
    justify-content: end;
    .final-card {
        background-color: white;
        width: 70%;
        border-radius: 5px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        .header-card {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background-color: #e6f4ff;
            margin-top: 1%;
            padding: 0.5%;
            .span-price {
                font-weight: 500;
                font-size: 0.9rem;
            }
            .span-name {
                font-size: 0.9rem;
            }  
        }
        .body-card {
            padding: 0.5%;
            margin-top: 1%;
            margin-bottom: 1%;
            margin-left: 2%;
            .span-dot {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: inline-block;
                padding-bottom: 3px;
                padding-top: 3px;
                margin-right: 7px;
            }
            .span-price{
                font-weight: 500;
                font-size: 0.9rem;
            }
            .span-name {
                font-size: 0.9rem;
            } 
            .span-time {
                color: gray;
                margin-left: 3%;
            }
        }
    }
}

// form update shipping controll
.detail-header {
    display: flex;
    flex-direction: row;
    .span-1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #EA9649;
        margin-right: 0.5%;
    }
    .span-2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        cursor: pointer;
    }
}
.detail-information {
    display: flex;
    flex-direction: row;
    margin-top: 0.5%;
    margin-bottom: 1%;
    .info-1 {
        width: 70%;
        background: #FFFFFF;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        padding: 1% 1.5% 1% 1.5%;
        margin-right: 2%;
        > h1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 19px;
            line-height: 27px;
            display: flex;
            align-items: center;
            color: #000000;
        }
        .info-data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left-side {
                display: flex;
                flex-direction: row;
                width: 60%;
                .title {
                    >p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        margin-bottom: 7px;
                        color: #000000;
                    }
                }
                .data {
                    margin-left: 2%;
                    >p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        margin-bottom: 7px;
                        color: #000000;
                    }
                }
            }
            .right-side {
                display: flex;
                flex-direction: row;
                width: 40%;
                justify-content: end;
                .title {
                    >p {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        margin-bottom: 7px;
                        color: #000000;
                    }
                }
                .data {
                    margin-left: 1%;
                    >p {
                        text-align: end;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        margin-bottom: 7px;
                        color: #000000;
                    }
                }
            }
        }
        .total-data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            > h1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 27px;
                display: flex;
                align-items: center;
                color: #000000;
            }
        }
    }
    .info-2 {
        width: 28%;
        background: #FFFFFF;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
        padding: 1% 1.5% 1% 1.5%;
        > h1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 19px;
            display: flex;
            align-items: center;
            color: #000000;
        }
    }
}

@media screen and (max-width: 1200px) {
    .detail-information {
        .info-1 {
            .info-data { 
                display: flex;
                flex-direction: column;
                .left-side {
                    width: 100%;
                    .title {
                        width: 135px;
                    }
                }
                .right-side {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    .title {
                        width: 135px;
                    }
                    .data {
                        margin-left: 2%;
                        >p {
                            text-align: start;
                        }
                    }
                }
            }
            .total-data {
                display: flex;
                flex-direction: column;
            }
        }
    }
}