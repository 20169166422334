.payment_method {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin: 1rem;
  padding: 1rem 1rem 0 1rem;
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}
.payment-name{
  color: #2e4cf3;
  font-size: 14px;
  font-weight: 500;
}
.payment-name:hover{
  cursor: pointer;
}