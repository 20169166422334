.card-item {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    box-shadow: rgb(37 107 254 / 25%) 0px 4px 25px;
    padding: 10px;
    .left-card {
        width: 200px;
        > img {
            width: 100px;
            height: 100px;
        }
        > p {
            font-weight: 500;
        }
    }
    .right-card {
        width: calc(100% - 200px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}