.header {
    padding: 20px;
    height: 200px;
    background-color: rgb(255, 255, 255);
    margin-left: 10px;
}

.tabs {
    width: 100%;
    border-bottom: 1cm;
    height: 10px;
    margin-top: 50px;
}

.card {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    position: relative;
    background-size: cover;
}

.form {
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.card_bottom {
    border: 1px solid rgb(231, 84, 84);
    margin-left: 50px;

}

.avatar {
    background-repeat: no-repeat;
    background-position: center ;
    background-size: cover;
    // border: 0.1875em solid #0F1C3F;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: flex-end;
}

.edit_avatar {
    width: 80px;
    height: 43px;
    background-color: black;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    // transform: rotate(180deg);
    opacity: 0.6;
    border-bottom: 0;
    text-align: center;
    color: aliceblue;
    font-size: 20px;

}
.edit_avatar:hover{
    opacity: 0.8;
    cursor : pointer;
}
.edit_cover_image{
    background-color: black;
    color: aliceblue;
    width: 100%;
    height: 43px;
    margin-top: 73px;
    opacity: 0.6;
    text-align: center;
    font-size: 30px;
}
.edit_cover_image:hover{
    opacity: 0.8;
    cursor : pointer;
}

.list {
    margin-left: 49px;
    font-weight: bold;
  }
.label{
    font-weight: bold;
    font-size: 1em;
}