.container {
  &__layout {
    background: #f5f5f5 !important;
    display: flex;
    justify-content: space-around;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
    max-width: 1080px;
    width: 100%;
  }
}
