.title {
  font-weight: 700;
  font-size: 24px;
  color: #202020;
  margin: 0;

  &__box {
  }
}

.sub-title {
  color: #a5a5a5;
  font-weight: 400;
  font-size: 12.5px;
}
