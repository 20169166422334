.forget-pw-container {
  position: relative;

  .forget-pw-content {
    position: absolute;
    padding: 0px 80px 0px 0px;
    width: 50%;

    .forget-pw-button {
      margin-top: 15px;
      background: #000 !important;
      border-color: #000 !important;
      font-size: 16px;
      color: #fff !important;
      width: 100%;
    }
  }
}
