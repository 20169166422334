.styleItem {
  margin-bottom: 8px;
  width: 240px;
  height: 160px;
  border: 1px solid rgb(197, 184, 184);
  border-radius: 4px;
}

.styleDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
}

.imei-item {
  cursor: pointer;

  .imei-item-span {
    margin-left: 5px;
  }
}

.imei-item:hover {
  background: silver !important;
}

.imei-item-select {
  border-radius: 10px;
}

.disabled-row {
  background: #cccc !important;
  pointer-events: none;
}

.table-row-light {
  background-color: #cccc;
}

.table-row-dark {
  background-color: red;
}

.layout-otp-form {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .form-otp-responsive {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.01em;
      color: #101828;
      flex: none;
      margin-bottom: 12px;
    }

    .custom-otp {
      width: 48px !important;
      height: 56px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px #1018280d !important;
      border-radius: 8px;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #101828;

      &:focus {
        border: 1px solid #7592e1 !important;
        padding: 4px !important;
        box-shadow: 0 0 4px 4px #c9d4f3 !important;
      }
    }

    .custom-container-otp {
      gap: 8px
    }

    .input-otp {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #101828;
    }

    .note {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #667085;
      flex: none;
      margin-bottom: 32px;

      >span {
        color: #101828;
      }
    }

    .count-length-otp {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #101828;
      flex: none;
      margin-bottom: 16px;
    }

    .btn-verify {
      width: 100%;
      background-color: #335BD3;
      border-color: #325de1;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      height: 44px;
      font-family: 'Inter';
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      font-style: normal;
      cursor: pointer;
    }

  }
}
.note-2 {
  margin-bottom: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // display: flex;
  align-items: center;
  color: #667085;
  gap: 4px;
  text-align: center;
  > p > span {
    color: #335bd3;
    font-weight: 600;
    cursor: pointer;
  }
}
.variant-btn{
  border-radius: 0 !important;
  margin: 2px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}