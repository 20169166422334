.list-product-header {
    font-size: 15px;
    font-weight: 500;
    min-height: 40px;
    max-height: 40px;
    border-bottom: 0.5px solid #cac7c7;

    .header-name {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.transport-product__item {
    width: 100%;
    padding: 30px 9px;
    min-height: 50px;
    max-height: 60px;
    // border-bottom: 0.5px solid #cac7c7;
    transition: background-color 0.5s ease;

    .transport-product__item-sku {
        margin-bottom: 0px;
        // margin-left: 15px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        // width: 110px;
        cursor: pointer;
    }

    .transport-product__item-name {
        margin: 0px 5px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        // min-width: 180px;
        max-width: 180px;
        text-align: center;
    }
}

.row_bonus {
    width: 100%;
    border-bottom: 0.5px solid #cac7c7;
}

.btnShift :hover {
    cursor: pointer;
  }
