.block {
  padding: 12px;
  border-bottom: solid 1px #f5f5f5;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  border-radius: 3px;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}
.payment-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
}
