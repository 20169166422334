@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 10px;
  border: 0 solid transparent;
  overflow: hidden;
  background-color: #eeeff3;
  margin: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

/* Edit Scrollbar */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #707070c9;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
.defaultBanNo .ant-form-item-control-input-content {
  display: grid;
  margin-left: 20px;
}

.chot-ca table {
  border-bottom: 1px solid black;
  border-collapse: collapse !important;
}

.chot-ca table td,
.chot-ca table th {
  border: 1px solid black !important;
}
/* .ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff !important;
  border-color: #5F73E2 !important;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  text-decoration: none !important;
}
.ant-btn:hover, .ant-btn:focus {
  color: #fff !important;
  border-color: #5F73E2 !important;
} */
.ant-btn-primary:focus {
  background-color: #5F73E2 !important;
  border-color: #5F73E2 !important;
}
*,*:focus,*:hover{
  outline:none;
}