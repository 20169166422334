.block {
  padding: 20px;
  border-bottom: solid 1px #f5f5f5;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  border-radius: 3px;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.view_product {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 1.25rem;
}
.card {
  box-shadow: 0 12px 23px 0 #5c37990a;
  border-radius: 1.25rem;
  border: 0 solid transparent;
  overflow: hidden;
}

.variant-image {
  width: 85px;
  height: 85px;
  cursor: pointer;
  border: 1px dashed #bcc6ff;
  transition: all 0.3s;

  &:hover {
    border-color: #5f73e2;
  }
}

.product-upload-img div[class='ant-upload ant-upload-select ant-upload-select-picture-card'] {
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.5) !important;
  background-color: #fff !important;
  height: 170px !important;
  width: 170px !important;
}

.product-upload-image div[class='ant-upload ant-upload-select ant-upload-select-picture-card'] {
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.5) !important;
  background-color: #fff !important;
  height: 130px !important;
  width: 130px !important;
}
//custom
.container-left {
  width: 50%;
  margin-right: 8px;
}
.container-right {
  width: 50%;
}
.title-container {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}
.dashboard_manager_bottom_row_col_parent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  border-bottom: 1px solid rgb(197, 184, 184);
}
.container-ttcb {
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
  background-color: white;
}

.price-product [class='ant-row ant-row-space-between ant-row-top'] {
  flex-flow: nowrap !important;
}

.input [class='ant-input ant-input-lg'] {
  width: 300px !important;
  margin-right: 8px !important;
}
.container-history {
  width: 100% !important;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: white;
}
.container-image {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  grid-gap: 16px !important;
}
