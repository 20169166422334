.user_information {
  display: flex;
  background-color: #fff;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
  border: 1px solid rgb(230, 218, 218);
  padding-bottom: 0.25rem;
  width: 15rem;
  border-radius: 15px;
  flex-direction: column;
  color: #565656;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.user_information > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 0;
  width: 100%;
  font-weight: 600;
  flex-wrap: wrap;

}

.notificationBox {
  background-color: #fff;
  border: solid 1px rgb(241, 233, 233);
  border-radius: 15px;
  // height: 150px;
  margin-top: 13px;
  width: 22rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .title {
    font-size: 16px;
    color: #565656;
    padding: 5px;
    font-weight: 600;
    border-bottom: solid 1px rgb(241, 233, 233);
  }
  .content {
    padding-top: 5px;
  }
}

.edit-submenu-arrow {
  color: rgba(0, 0, 0, 0.85);
}

.edit-submenu-arrow:hover {
  a {
    color: #5f73e2 !important;
  }
}

.item-info{
  font-size: 16px;
  font-weight: 500;
  // font-family: 'Inter';
  margin-bottom: 30px;
  &__row{
    margin-bottom: 10px;
  }
}