.background-container {
    background: whitesmoke;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    .text-h1{
        color: #0b317a;
        font-size: 2rem;
        font-weight: 600;
        margin-top: -17%;
    }
    .cart-order {
        width: 600px;
        background-color: white;
        border-radius: 2%;
        box-shadow: 1px 1px 5px 1px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .header-data {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: whitesmoke;
        padding: 1%;
        border-bottom: 1px solid silver;
        .header-item-0{
            width: 56%;
            border-right: 1px solid silver;
            margin-right: 2%;
            padding-left: 1%;
            .header-span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
            }
            
        }
        .header-item-1{
            width: 20%;
            border-right: 1px solid silver;
            display: flex;
            padding-right: 2%;
            .header-span {
                width: 100%;
                text-align: end;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
            }
        }
        .header-item-2{
            width: 20%;
            display: flex;
            align-items: end;
            padding-right: 2%;
            .header-span {
                width: 100%;
                text-align: end;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
            }
        }
    }
    
    .body-data {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: #fafafa;
        padding: 1%;
        border-bottom: 1px dashed silver;
        .body-item-0{
            width: 56%;
            border-right: 0.5px dashed silver;
            margin-right: 2%;
            height: 100%;
            padding-right: 2px;
            padding-left: 1%;
            .body-span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
            }
        }
        .body-item-1{
            width: 20%;
            padding-right: 2%;
            border-right: 0.5px dashed silver;
            display: flex;
            align-items: end;
            .body-span {
                width: 100%;
                text-align: end;
                text-align: end;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
            }
        }
        .body-item-2{
            width: 20%;
            display: flex;
            align-items: end;
            padding-right: 2%;
            .body-span {
                width: 100%;
                text-align: end;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }

    .footer-data {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: white;
        padding-left: 1%;
        padding-right: 1%;
        .footer-item-0{
            width: 72%;
            margin-right: 4%;
            height: 100%;
            padding-right: 2px;
            padding-left: 1%;
            .footer-span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
            }
        }
        .footer-item-1{
            width: 20%;
            height: 100%;
            display: flex;
            align-items: end;

            .footer-span {
                text-align: end;
                width: 100%;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }

    .end-data {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: white;
        padding-left: 1%;
        padding-right: 1%;
        margin-top: 3%;
        margin-bottom: 3%;
        .end-item-0{
            width: 72%;
            margin-right: 4%;
            height: 100%;
            padding-right: 2px;
            padding-left: 1%;
            .end-span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 1.125rem;
            }
        }
        .end-item-1{
            width: 20%;
            height: 100%;
            display: flex;
            align-items: end;
            .end-span {
                text-align: end;
                width: 100%;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 1.125rem;
            }
        }
    }

}

@media screen and (max-width: 1900px)  {
    .cart-order {
        width: 600px !important;
    }
}

@media screen and (max-width: 1600px)  {
    .cart-order {
        width: 600px !important;
    }
}

@media screen and (max-width: 650px)  {
    .cart-order {
        width: 90% !important;
    }
}