.table-items-columns {
    thead > tr > th {
      background: rgb(91, 107, 232) !important;
      color: white;
    }
    tr > td {
        background:#ffffff !important;
    }
    tr:hover > td { 
      background: #f0f5ff !important;
    }

  }
.variant-columns {
    margin-top: 12px;
    .table-items-columns tr > td {
        background:#ffffff !important;
    }
    background: rgb(245, 245, 245);
    tr:hover > td {
      background: #E6ECFF !important;
    }

}
.new-table-items-columns {
    thead > tr > th {
        background: #52C41A !important;
        color: rgb(48, 44, 44);
      }
      tr:hover > td { 
        background: rgb(246, 255, 237) !important;
      }
}
